import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #2E203B;
  `

const Title = styled.h4`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 45px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
`
const Image = ({ img }) => (
  <>
    <DesktopFlex>
      <Img
        style={{ width: 800 }}
        fluid={img.image.localFile.childImageSharp.fluid}
      ></Img>
    </DesktopFlex>
    <MobileFlex>
      <Img
        style={{ width: 300 }}
        fluid={img.image_mobile.localFile.childImageSharp.fluid}
      ></Img>
    </MobileFlex>
  </>
)

const Businesses = ({ section }) => {
  return (
    <>
      <Section background={<Bg />}>
        <Flex mx={[15, 0]} pt={[70, 50]} pb={[80, 120]} justifyContent="center">
          <Flex flexDirection="column">
            <Title>{section.title}</Title>
            <Flex justifyContent="center" pt={[10, 20]}>
              <Image img={section}></Image>
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </>
  )
}

export default Businesses
