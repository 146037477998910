import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import Triangle from '../../static/img/sales_landing/triangle3_software.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #2E203B;
  @media (min-width: 512px) {

  background-color: #362745;
  }

  `

const Text = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */
  max-width: 455px;
  color: #ffffff;
`
const BorderText = styled.div`
  border-top: 2px solid;
  border-radius: 4px;
  border-color: #463562;
  width: 100%;
  padding-bottom: ${props => props.padding || '0px'};
`

const Triangles = () => (
  <DesktopFlex>
    <Triangle style={{ position: 'absolute', right: 0, top: 1748 }} />
  </DesktopFlex>
)

const Software = ({ section }) => {
  return (
    <>
      <Triangles />
      <Section background={<Bg />}>
        <Flex
          pt={[0, 100]}
          pb={[60, 160]}
          flexDirection={['column', 'row']}
          mx={[15, 0]}
        >
          <Flex justifyContent="center" flexDirection="column">
            <DesktopFlex>
              <BorderText padding={'23px'} />
            </DesktopFlex>
            <Text
              dangerouslySetInnerHTML={{
                __html: section.desc,
              }}
            ></Text>
            <DesktopFlex>
              <BorderText />
            </DesktopFlex>
          </Flex>
          <Flex
            width={1}
            flexDirection={'column'}
            style={{ alignItems: 'flex-end' }}
          >
            <Flex pb={[0, 10]} flexDirection={['column', 'row']}>
              <Box pb={[20, 0]} pr={[0, 15]}>
                <Img
                  fluid={section.images.img1.localFile.childImageSharp.fluid}
                  style={{ width: 342 }}
                ></Img>
              </Box>
              <Img
                fluid={section.images.img2.localFile.childImageSharp.fluid}
                style={{ width: 342 }}
              ></Img>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box py={[20, 0]} pr={[0, 15]}>
                <Img
                  fluid={section.images.img3.localFile.childImageSharp.fluid}
                  style={{ width: 342 }}
                ></Img>
              </Box>
              <Img
                fluid={section.images.img4.localFile.childImageSharp.fluid}
                style={{ width: 342 }}
              ></Img>
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </>
  )
}

export default Software
