import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import Triangle_right from '../../static/img/sales_landing/triangle1_agent.svg'
import Triangle_left from '../../static/img/sales_landing/triangle2_virtualAgent.svg'
import Triangles_mobile from '../../static/img/sales_landing/triangleMobile_agent.svg'
import Triangles_mobile_bottom from '../../static/img/sales_landing/triangleMobile_bottom_agent.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: linear-gradient(178.1deg, #2E203B 16.5%, #2E203B 76.06%, rgba(46, 32, 59, 0) 89.11%);
  transform: rotate(-180deg);
  margin-top: -216px;
  z-index: -2px;

  `

const Text = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #ffffff;
`
const Sum = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  /* or 42px */
  padding: 10px 15px 10px 15px;
  color: #ffffff;
`

const Icons = styled(Flex)`
  margin-bottom: 3px;

  @media (min-width: 512px) {
    border-top: 2px solid;
    border-color: #463562;
  }
`
const Desc = ({ icons }) => (
  <Flex flexDirection="column" width={[1, 400]} pt={[40, 0]} pl={[15, 70]}>
    <Icons>
      <Flex pt={[0, 20]}>
        <Img
          fluid={icons.icons.icon1.localFile.childImageSharp.fluid}
          style={{ width: 50, height: 50 }}
        ></Img>
        <Sum>+</Sum>
        <Img
          fluid={icons.icons.icon2.localFile.childImageSharp.fluid}
          style={{ width: 50, height: 50 }}
        ></Img>
      </Flex>
    </Icons>
    <Flex style={{ borderBottom: '2px solid', borderColor: '#463562' }}>
      <Text
        dangerouslySetInnerHTML={{
          __html: icons.text,
        }}
      />
    </Flex>
  </Flex>
)

const Triangles = () => (
  <>
    <DesktopFlex>
      <Triangle_right style={{ position: 'absolute', right: 0, top: 595 }} />
      <Triangle_left style={{ position: 'absolute', left: 0, top: 1247 }} />
    </DesktopFlex>
    <MobileFlex>
      <Triangles_mobile style={{ position: 'absolute', right: 30, top: 520 }} />
      <Triangles_mobile_bottom
        style={{ position: 'absolute', right: 50, top: 1227, zIndex: 20 }}
      />
    </MobileFlex>
  </>
)

const VirtualAgent = ({ section }) => {
  return (
    <>
      <Triangles />
      <Section background={<Bg />}>
        <Flex pt={[150, 100]} pb={[60, 100]} flexDirection={['column', 'row']}>
          <DesktopFlex>
            <Img
              fluid={section.image.localFile.childImageSharp.fluid}
              style={{ width: 750, marginTop: -150 }}
            ></Img>
          </DesktopFlex>
          <MobileFlex>
            <Img
              fluid={section.image_mobile.localFile.childImageSharp.fluid}
              style={{ width: 750, marginTop: -150 }}
            ></Img>
          </MobileFlex>
          <Desc icons={section.desc}></Desc>
        </Flex>
      </Section>
    </>
  )
}

export default VirtualAgent
