import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import WhiteLogo from '../../static/img/hubtype_logo_black.svg'

const FooterContainer = styled(Flex)`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -150px;
    height: 100%;
    width: 100%;
    left: 0px;
    right: 0px;
    background: #f2f2f2;
    z-index: -10;
  }
`
const Text = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  text-align: right;
  color: #000000;
`

const Footer = ({ section }) => {
  return (
    <>
      <FooterContainer
        pt={20}
        pb={'5px'}
        mt={-46}
        css={{
          position: 'relative',
          background: '#FFFFFF',
        }}
        flexDirection="column"
      >
        <Flex px={[20, 160]} style={{ alignItems: 'center' }}>
          <Flex mr={[20, 0]}>
            <a href="/">
              <WhiteLogo />
            </a>
          </Flex>
          <Flex width={1} justifyContent="flex-end">
            <Flex flexDirection={['column', 'row']}>
              <Box>
                <Text>{section.email}</Text>
              </Box>
              <Box ml={[0, 40]}>
                <Text>{section.website}</Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </FooterContainer>
    </>
  )
}

export default Footer
