import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import Triangle from '../../static/img/sales_landing/triangle_channels.svg'
import TriangleMobile from '../../static/img/sales_landing/triangleMobile_channels.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: linear-gradient(178.1deg, #2E203B 16.5%, #2E203B 76.06%, rgba(46, 32, 59, 0) 89.11%);
  transform: rotate(-180deg);
  @media (min-width: 512px) {
    margin-top: -180px;
  }
  margin-top: -190px;

  `

const Text = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #ffffff;
  @media (min-width: 512px) {
    margin-top: 28x;
  }
`

const BordersDesktop = styled.div`
  @media (min-width: 512px) {
    border-top: 2px solid;
    margin-bottom: 25px;
    border-color: #463562;
  }
`

const BorderLineMobile = styled.div`
  border-top: solid;
  border-color: #463562;
  width: 100%;
  padding-bottom: 50px;
`

// props.media && props.media.localFile && (
//   <Img
//     objectFit="cover"
//     objectPosition="50% 50%"
//     style={props.style}
//     fluid={props.media.localFile.childImageSharp.fluid}
//   />
// )}

const ImageChannels = ({ image }) => {
  return (
    image.localFile && (
      <Img
        style={{ position: 'absolute', top: 1926, width: 780, left: 35 }}
        fluid={image.localFile.childImageSharp.fluid}
      ></Img>
    )
  )
}
const Triangles = () => (
  <>
    <DesktopFlex>
      <Triangle style={{ position: 'absolute', left: 20, top: 2266 }} />
    </DesktopFlex>
    <MobileFlex>
      <TriangleMobile style={{ position: 'absolute', right: 5, top: 2638 }} />
    </MobileFlex>
  </>
)

const ImageChannelsMobile = ({ image }) => {
  return (
    image.localFile && (
      <Img
        style={{ position: 'absolute', top: 2304, width: '100%' }}
        fluid={image.localFile.childImageSharp.fluid}
      ></Img>
    )
  )
}

const Channels = ({ section }) => {
  return (
    <>
      <Triangles />
      <DesktopFlex>
        <ImageChannels image={section.images.image} />
      </DesktopFlex>
      <MobileFlex>
        <ImageChannelsMobile image={section.images.image_mobile} />
      </MobileFlex>
      <Section background={<Bg />}>
        <Flex pt={[0, 20]} pb={[400, 250]} mx={[15, 0]}>
          <Flex
            justifyContent="flex-end"
            pl={[0, 786]}
            pt={[0, 76]}
            flexDirection={['column', '']}
          >
            <MobileFlex>
              <BorderLineMobile />
            </MobileFlex>
            <BordersDesktop />
            <Text
              dangerouslySetInnerHTML={{
                __html: section.desc,
              }}
            />
            <BordersDesktop />
          </Flex>
        </Flex>
      </Section>
    </>
  )
}

export default Channels
