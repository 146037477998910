import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Section from '../Section'
import Logo1 from '../../static/img/sales_landing/use_cases/logo1.svg'
import Logo2 from '../../static/img/sales_landing/use_cases/logo2.svg'
import Logo3 from '../../static/img/sales_landing/use_cases/logo3.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #362745;
  transform: rotate(-180deg);

  `
const UseCaseBg = styled(Flex)`
  background: linear-gradient(180deg, #523b6a 0%, rgba(59, 42, 75, 0) 86%);
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 45px;
  /* identical to box height */
  @media (min-width: 512px) {
    text-align: center;
    padding-right: 0;
  }
  padding-right: 54px;

  color: #ffffff;
`
const Desc = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #e6dbff;
`
const UseCaseTitle = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  /* or 121% */
  color: #ffffff;
`

const Case = ({ useCase, index }) => {
  const logos = [<Logo1 />, <Logo2 />, <Logo3 />]
  return (
    <>
      <UseCaseBg
        width={[1, 1 / 3]}
        flexDirection={['column']}
        mx={[0, 15]}
        p={[20, 25]}
      >
        <Flex>
          <Box>
            <UseCaseTitle>{useCase.title}</UseCaseTitle>
          </Box>
          <Flex mt={[0, -10]} width={1} justifyContent="flex-end">
            {logos[index]}
          </Flex>
        </Flex>
        <Box pt={20}>
          <Desc
            dangerouslySetInnerHTML={{
              __html: useCase.desc,
            }}
          ></Desc>
        </Box>
      </UseCaseBg>
    </>
  )
}

const UseCases = ({ section }) => {
  return (
    <>
      <Section background={<Bg />}>
        <Flex pt={[60, 40]} pb={[50, 100]} mx={[15, 0]}>
          <Flex justifyContent="space-between" flexDirection={['column']}>
            <Title>{section.title}</Title>
            <Flex flexDirection={['column', 'row']} pt={[9, 20]}>
              {Object.values(section.usecases).map((c, i) => {
                return <Case key={i} useCase={c} index={i}></Case>
              })}
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </>
  )
}

export default UseCases
