import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'
import Logo from '../../static/img/hubtype_logo_white.svg'
import Section from '../Section'
import WhatsappLogo from '../../static/img/whatsapp_logo.svg'
import WhatsappLogoMobile from '../../static/img/sales_landing/whatsapp_logo.svg'

const Title = styled.h1`
  margin: 60px 0px 28px;
  position: relative;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 86px;
  line-height: 97px;
  color: white;
  @media (max-width: 512px) {
    font-size: 44px;
    line-height: 60px;
    padding: 0px 15px;
  }
`
const Subtitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  /* or 28px */

  @media (min-width: 512px) {
    font-size: 20px;
    padding: 0px 101px 0px 101px;
  }
  text-align: center;

  color: #ffffff;
`
const WhatsappBanner = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 132.19%;
  /* identical to box height, or 20px */
  @media (max-width: 512px) {
    margin-left: 0px;
  }
  text-align: center;
  margin-left: 10px;
  margin-top: 3px;
  color: #ffffff;
`
const LogoHeader = styled(Flex)`
  position: absolute;
  margin: 30px;
  z-index: 11;
`
const WhatsappHeader = styled(Flex)`
  position: absolute;
  margin-top: 30px;
  @media (min-width: 512px) {
    right: 58px;
  }
  @media (max-width: 512px) {
    right 15px;
  }
`

const Intro = ({ section }) => {
  const { bgHeader, bgHeaderMobile } = useStaticQuery(
    graphql`
      query {
        bgHeader: file(relativePath: { eq: "home/bg_header@2x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1300, quality: 75) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bgHeaderMobile: file(
          relativePath: { eq: "sales_landing/bg_header_mobile.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 200, quality: 50) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  let bg = (
    <>
      <Flex
        flex="1 1 auto"
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -2,
          width: '100%',
          height: '100%',
          backgroundColor: '#362745',
        }}
      />
      <DesktopFlex>
        <Img
          fluid={bgHeader.childImageSharp.fluid}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
          }}
        />
      </DesktopFlex>
      <MobileFlex>
        <Img
          fluid={bgHeaderMobile.childImageSharp.fluid}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
          }}
        />
      </MobileFlex>
    </>
  )
  const Header = ({ text }) => (
    <>
      <DesktopFlex>
        <LogoHeader>
          <a href="/">
            <Logo style={{ zIndex: 10 }}></Logo>
          </a>
        </LogoHeader>
        <WhatsappHeader>
          <WhatsappLogo></WhatsappLogo>
          <WhatsappBanner>{text.whatsapp_text}</WhatsappBanner>
        </WhatsappHeader>
      </DesktopFlex>
      <MobileFlex>
        <LogoHeader>
          <Logo></Logo>
        </LogoHeader>
        <WhatsappHeader>
          <WhatsappLogoMobile />
          <WhatsappBanner style={{ width: 150 }}>
            {text.whatsapp_text_mobile}
          </WhatsappBanner>
        </WhatsappHeader>
      </MobileFlex>
    </>
  )

  return (
    <>
      <Header text={section}></Header>
      <Section background={bg}>
        <Flex pt={[90, 150]} pb={[180, 170]} flexDirection="column">
          <Title>{section.title}</Title>
          <Subtitle>{section.subtitle}</Subtitle>
        </Flex>
      </Section>
    </>
  )
}

export default Intro
