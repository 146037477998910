import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'
import Triangle1 from '../../static/img/sales_landing/triangle1_caseStudy.svg'
import Triangle2 from '../../static/img/sales_landing/triangle2_caseStudy.svg'
import TriangleMobile_right from '../../static/img/sales_landing/triangleMobile_right_caseStudy.svg'
import TriangleMobile_left from '../../static/img/sales_landing/triangleMobile_left_caseStudy.svg'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #362745;

  `
const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 45px;
  /* identical to box height */

  color: #ffffff;
`

const Text = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #ffffff;
`

const Percentage = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 54px;
  line-height: 124.84%;
  /* identical to box height, or 67px */

  color: #c0ffaa;
`
const TextPercentage = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #e5e5e5;
`
const Triangles = () => (
  <>
    <DesktopFlex>
      <Triangle1
        style={{ position: 'absolute', right: 82, top: 2641, zIndex: 0 }}
      />
      <Triangle2 style={{ position: 'absolute', right: 432, top: 2866 }} />
    </DesktopFlex>
    <MobileFlex>
      <TriangleMobile_right
        style={{ position: 'absolute', right: 0, top: 3680 }}
      />
      <TriangleMobile_left
        style={{ position: 'absolute', left: 40, top: 3910 }}
      />
    </MobileFlex>
  </>
)

const CaseStudy = ({ section }) => {
  return (
    <>
      <Triangles />
      <Section background={<Bg />}>
        <Flex
          mx={[15, 0]}
          pt={[80, 100]}
          pb={[90, 140]}
          flexDirection={'column'}
        >
          <Title>{section.title}</Title>
          <Flex
            flexDirection={['column', 'row']}
            justifyContent={['space-between']}
          >
            <Box width={[1, 0.63]}>
              <Text
                dangerouslySetInnerHTML={{
                  __html: section.desc,
                }}
              />
            </Box>
            <Flex
              flexDirection={['', 'column']}
              flexWrap={['wrap', '']}
              pb={[20, 0]}
            >
              {Object.values(section.percentages).map((e, i) => {
                if (e.number != '') {
                  return (
                    <Box key={i} width={[0.47, 1 / 2]} mx={[0, 90]}>
                      <Percentage>{e.number}</Percentage>
                      <TextPercentage>{e.text}</TextPercentage>
                    </Box>
                  )
                }
              })}
            </Flex>
            <Flex width={[1, 1 / 3]} mt={[0, -92]} justifyContent="flex-end">
              <Img
                style={{ width: 300, height: 570 }}
                fluid={section.image.localFile.childImageSharp.fluid}
              ></Img>
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </>
  )
}

export default CaseStudy
