import React from 'react'
import { BreakpointProvider } from 'react-socks'
import { graphql } from 'gatsby'

import Businesses from '../components/sales_landing/businesses'
import CaseStudy from '../components/sales_landing/case_study'
import Channels from '../components/sales_landing/channels'
import Contact from '../components/sales_landing/contact'
import Footer from '../components/sales_landing/footer'
import Head from '../components/head'
import Intro from '../components/sales_landing/intro'
import Software from '../components/sales_landing/software'
import UseCases from '../components/sales_landing/use_cases'
import VirtualAgent from '../components/sales_landing/virtual_agent'

const SalesLanding = ({ data, pageContext }) => {
  const { wordpressPage: page } = data
  const salesLanding = page.polylang_translations.filter(
    e => e.polylang_current_lang === pageContext.lang
  )[0]

  let {
    acf: {
      intro,
      virtual_agent,
      software_stack,
      channels,
      use_cases,
      case_study,
      business,
      contact,
      footer_sales,
    },
    yoast_meta,
  } = salesLanding
  let props = { pageContext }
  return (
    <BreakpointProvider style={{ marginBottom: -10 }}>
      <Head
        alternates={pageContext.i18n}
        autopilot={pageContext.autopilot}
        description={yoast_meta.yoast_wpseo_metadesc}
        lang={pageContext.lang}
        title={yoast_meta.yoast_wpseo_title}
        uri={pageContext.relativeUrl}
      />
      <Intro {...props} section={intro} />
      <VirtualAgent {...props} section={virtual_agent} />
      <Software {...props} section={software_stack} />
      <Channels {...props} section={channels}></Channels>
      <CaseStudy {...props} section={case_study}></CaseStudy>
      <Businesses {...props} section={business}></Businesses>
      <UseCases {...props} section={use_cases}></UseCases>
      <Contact {...props} slug={salesLanding.slug} section={contact}></Contact>
      <Footer {...props} section={footer_sales}></Footer>

      {/* <UseCasesContainer {...props} section={not_ready_to_talk}>
        <CaseDescription pageContext={pageContext} section={case_description} />
        <HubtypePoints pageContext={pageContext} section={hubtype_points} />

        {pageContext.template === 'UseCaseMarketing' && (
          <MarketingFirstNumber number={number_1} />
        )}
        {pageContext.template === 'UseCaseCustomerService' && (
          <Information pageContext={pageContext} information={information} />
        )}
        {pageContext.template === 'UseCaseSales' && (
          <>
            <SalesFirstNumber number={number_1} />
            <Conversations
              info={message_conversation}
              pageContext={pageContext}
            />
          </>
        )}
        <Reasons pageContext={pageContext} section={reasons} />
        {(pageContext.template === 'UseCaseCustomerService' ||
          pageContext.template === 'UseCaseMarketing') && (
          <Conversations
            info={message_conversation}
            pageContext={pageContext}
          />
        )}
        {pageContext.template === 'UseCaseMarketing' && (
          <>
            <Contact pageContext={pageContext} section={contact} />
            <Integrate pageContext={pageContext} section={integrate} />
          </>
        )}
        {pageContext.template === 'UseCaseSales' && (
          <SalesSecondNumber number={number_2} information={information} />
        )}
        <Factors pageContext={pageContext} section={factors} />
        {pageContext.template === 'UseCaseSales' && (
          <Information pageContext={pageContext} information={information} />
        )}
        {pageContext.template === 'UseCaseCustomerService' && (
          <CustomerServiceNumber number={number_2} />
        )}
        {pageContext.template === 'UseCaseMarketing' && (
          <MarketingSecondNumber number={number_2} />
        )}

        {(pageContext.template === 'UseCaseCustomerService' ||
          pageContext.template === 'UseCaseSales') && (
          <>
            <Contact pageContext={pageContext} section={contact} />
            <NumberStory pageContext={pageContext} section={number_story} />
          </>
        )}
        {pageContext.template === 'UseCaseMarketing' && (
          <Purchase pageContext={pageContext} section={purchase} />
        )}
        {pageContext.template === 'UseCaseCustomerService' && (
          <Integrate pageContext={pageContext} section={integrate} />
        )}
        {(pageContext.template === 'UseCaseCustomerService' ||
          pageContext.template === 'UseCaseMarketing') && (
          <ItsTime pageContext={pageContext} section={its_time} />
        )}
        <Connect pageContext={pageContext} section={connect} />
      </UseCasesContainer> */}
    </BreakpointProvider>
  )
}

export default SalesLanding
export const pageQuery = graphql`
  query LandingByType($template: String!) {
    wordpressPage(acf: { type: { eq: $template } }) {
      polylang_translations {
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
        polylang_current_lang
        wordpress_id
        title
        slug
        acf {
          footer_sales {
            email
            website
          }
          contact {
            title
            subtitle
            form {
              input1
              input2
              input3
              input4
              input5
              input6
              input7
              message_text
              input8
              accept_form
              button
            }
          }
          business {
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 995, quality: 10) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            image_mobile {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 10) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          case_study {
            title
            desc
            percentages {
              percentage1 {
                number
                text
              }
              percentage2 {
                number
                text
              }
              percentage3 {
                number
                text
              }
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 10) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          use_cases {
            title
            usecases {
              use_case1 {
                title
                desc
                image {
                  localFile {
                    childImageSharp {
                      fixed(width: 60, height: 61) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
              use_case2 {
                title
                desc
              }
              use_case3 {
                title
                desc
                image {
                  localFile {
                    childImageSharp {
                      fixed(width: 60, height: 61) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
          }
          channels {
            desc
            images {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 10) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              image_mobile {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 10) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          intro {
            title
            subtitle
            whatsapp_text
            whatsapp_text_mobile
          }
          virtual_agent {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 10) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            image_mobile {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 10) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            desc {
              text
              icons {
                icon1 {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 50, quality: 10) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                icon2 {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 50, quality: 10) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          software_stack {
            desc
            images {
              img1 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              img2 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              img3 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              img4 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1500) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
      id
    }
  }
`
