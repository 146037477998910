import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Section from '../Section'
import { OutlineButton } from '../buttons'
import {
  FormContainer,
  Selector,
  FieldContainer,
  RadioInput,
  MessageBox,
  Text,
} from '../forms'
import * as EmailValidator from 'email-validator'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -2;
  background: #F2F2F2;
  `

const Title = styled.h4`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  /* identical to box height */

  color: #441d76;
`
const Subtitle = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  width: 350px;
`
const FormText = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #333333;
`

const FeedbackTitle = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 132.19%;
  color: #333333;
  padding-bottom: 5px;
  padding-top: 30px;
`

const FeedbackContent = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 15px;
  color: #696973;
`

const Contact = props => {
  let initialState = {
    comesFrom: 'web',
    slug: props.slug,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    company: '',
    country: '',
    message: '',
  }
  const [userData, updateData] = useState(initialState)
  const [isSend, setIsSend] = useState(undefined)
  const [emailCorrect, setEmailCorrect] = useState(true)
  const handleChange = name => event => {
    updateData({ ...userData, [name]: event.target.value })
  }
  const validateData = userData => {
    let propertiesRequired = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      title: userData.title,

      company: userData.company,
      country: userData.country,

      GDPRcheckBox: userData.GDPRcheckBox,
      lang: props.pageContext.lang,
      comesFrom: userData.comesFrom,
    }
    let propertiesNotRequired = {
      message: userData.message,
      slug: userData.slug,
    }
    let properties = { ...propertiesRequired, ...propertiesNotRequired }
    let invalidProperties = propertiesRequired => {
      let res = Object.entries(propertiesRequired).some(([k, v]) => v == '')
      return (
        res ||
        (propertiesRequired.estMAU == '' &&
          propertiesRequired.trafficVolume == '')
      )
    }
    if (invalidProperties(propertiesRequired)) {
      setIsSend(false)
    } else {
      if (
        userData.email.includes('mail.') ||
        !EmailValidator.validate(userData.email)
      ) {
        setIsSend(false)
        setEmailCorrect(false)
      } else {
        setIsSend(true)
        sendClick(properties, props.pageContext.template)
      }
    }
  }
  const sendClick = properties => {
    if (typeof window !== 'undefined') {
      setEmailCorrect(true)
      window.analytics.identify(properties)
      window.analytics.track('Industries Form', properties)
      let {
        email: Email,
        firstName: FirstName,
        lastName: LastName,
        ...custom
      } = properties
      window.Autopilot.run('associate', {
        Email,
        FirstName,
        LastName,
        custom,
      })
      updateData(initialState)
    }
  }

  const TrackingPixels = () => (
    <>
      {props.pageContext.template == 'LandingBanking' && (
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=1852764&conversionId=1085147&fmt=gif"
        />
      )}
      {props.pageContext.template == 'LandingEcommerce' && (
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=1852764&conversionId=1085139&fmt=gif"
        />
      )}
      {props.pageContext.template == 'LandingInsurance' && (
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=1852764&conversionId=1085155&fmt=gif"
        />
      )}
    </>
  )

  const Feedback = () => {
    if (isSend == true) {
      return (
        <>
          <TrackingPixels />
          <FeedbackTitle>Success! Message sent.</FeedbackTitle>
          <FeedbackContent>
            A team member will reach out to you within one business day.
          </FeedbackContent>
        </>
      )
    } else if (isSend == false) {
      return (
        <FeedbackContent>Please enter all required fields.</FeedbackContent>
      )
    } else {
      return <></>
    }
  }
  return (
    <>
      <Section background={<Bg />}>
        <Flex mx={[15, 0]} pt={[50, 85]} pb={[80, 150]}>
          <Flex flexDirection={'column'} width={1}>
            <Title>{props.section.title}</Title>
            <Subtitle>{props.section.subtitle}</Subtitle>
            <FormContainer>
              <Text
                onChange={handleChange('firstName')}
                label={`${props.section.form.input1}`}
                value={userData.firstName}
              />
              <Text
                onChange={handleChange('lastName')}
                label={`${props.section.form.input2}`}
                value={userData.lastName}
              />
              <Text
                onChange={handleChange('email')}
                label={`${props.section.form.input3}`}
                value={userData.email}
                type="email"
                name="email"
                autoComplete="email"
              />
              <Text
                onChange={handleChange('phone')}
                label={`${props.section.form.input4}`}
                value={userData.phone}
              />
              <Text
                onChange={handleChange('title')}
                label={`${props.section.form.input5}`}
                value={userData.title}
              />
              <Text
                onChange={handleChange('company')}
                label={`${props.section.form.input6}`}
                value={userData.company}
              />
              <Text
                onChange={handleChange('country')}
                label={`${props.section.form.input7}`}
                value={userData.country}
              />
            </FormContainer>
            <Box pt={[0, 20]} width={[1, 0.65]}>
              <FormText>{props.section.form.message_text}</FormText>
              <MessageBox
                label={`${props.section.form.input8}`}
                onChange={handleChange('message')}
                value={userData.message}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userData.GDPRcheckBox}
                    onChange={props =>
                      updateData({
                        ...userData,
                        GDPRcheckBox: props.target.checked,
                      })
                    }
                  />
                }
                label={<FormText>{props.section.form.accept_form}</FormText>}
              />
              <Flex pt={20} height={40} width={[1, '20.5%']}>
                <OutlineButton
                  color="white"
                  hoverBackground="#FF4B76"
                  style={{
                    background: '#FF2B5E',
                    height: '40px',
                    width: '100%',
                  }}
                  onClick={() => validateData(userData)}
                >
                  SEND
                </OutlineButton>
              </Flex>
            </Box>
            <Feedback />
          </Flex>
        </Flex>
      </Section>
    </>
  )
}

export default Contact
